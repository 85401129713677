<afj-navbar *ngIf="router.url!='/'"></afj-navbar>
<afj-loader-bar *ngIf="show"></afj-loader-bar>
<div class="container-fluid">
    <ngx-alerts></ngx-alerts>
    <router-outlet *ngIf="!_auth.getCurrentUser()"></router-outlet>
    <div class="row" *ngIf="_auth.getCurrentUser()">
        
        <afj-menu></afj-menu>
        
        <main role="main" class="col-md-8 ml-sm-auto col-lg-9 col-xl-9 px-md-5">
            <router-outlet *ngIf="_auth.getCurrentUser()"></router-outlet>
        </main>
    </div>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'afj-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.css']
})
export class RecoveryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  recovery() {

  }

}


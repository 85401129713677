import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'afj-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(private _auth:AuthService,private router:Router) { }

  ngOnInit(): void {
  }

  logOut(){
    this._auth.logout();
    this.router.navigate(['/']);
  }
}

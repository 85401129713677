import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from "@angular/forms";

import { MenuComponent } from './components/menu/menu.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { TitleComponent } from './components/title/title.component';
import { MainComponent } from './components/main/main.component';
import { MainFooterComponent } from './components/main-footer/main-footer.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { LoaderBarComponent } from './components/loader-bar/loader-bar.component';
import { FormUserComponent } from './components/form-user/form-user.component';
import { FormCompanyComponent } from './components/form-company/form-company.component';
import { AvatarPipe } from "../../pipes/avatar.pipe";
import { SearchPipe } from '../../pipes/search.pipe';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { FormAdminComponent } from './components/form-admin/form-admin.component';
import { RecoveryComponent } from './components/recovery/recovery.component';



@NgModule({
  declarations: [
    MenuComponent, 
    NavbarComponent, 
    TitleComponent, 
    MainComponent, 
    MainFooterComponent, 
    LoginComponent, 
    SignupComponent, 
    LoaderBarComponent, 
    FormUserComponent, 
    FormCompanyComponent,
    AvatarPipe,
    SearchPipe,
    ConfirmModalComponent,
    FormAdminComponent,
    RecoveryComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule
  ],
  exports:[
    MenuComponent,
    NavbarComponent,
    TitleComponent,
    MainComponent,
    LoaderBarComponent,
    AvatarPipe,
    SearchPipe,
    ConfirmModalComponent
  ]
})
export class SharedModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'afj-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  public cssClass:string = "signup-user";
  public question:string = "";
  public answer:string = ``;
  public steps:string[] = [];
  public type:number = 0;
  public isSigned:Boolean = false;
  public signedMail:String = "";

  constructor( 
    private router:Router
  ) { }

  ngOnInit(): void {
    if(this.router.url == "/signup_company"){
      this.question = "Cuenta de empresa";
      this.answer = ``;
      this.steps = [
        "Crea tu cuenta de empresa",
        "Sube una o varias plazas vacantes",
        "Revisa los candidatos que se postulen",
        "Contacta los candidatos"
      ];
      this.cssClass = "signup-company";
      this.type=1;
    }
    if(this.router.url == "/signup_user"){
      this.question = "Cuenta de estudiante";
      this.answer = ``;
      this.steps = [
        "Crea tu cuenta de estudiante",
        "Actualiza tu perfil personal y profesional",
        "Revisa las plazas vacantes publicadas",
        "Postúlate a una o varias plazas"
      ];
      this.cssClass = "signup-user";
      this.type=2;
    }
    if(this.router.url == "/signup_admin"){
      this.question = "¿Qué es una cuenta de administrador?";
      this.steps = [
        "Crea tu cuenta de estudiante",
        "Actualiza tu perfil personal y profesional",
        "Revisa las plazas vacantes publicadas",
        "Postúlate a una o varias plazas"
      ];
      this.cssClass = "signup-user";
      this.type=0;
    }
  }

  signed(event){
    this.isSigned = event.signed;
    this.signedMail = event.email;
  }
}

<div class="card card-login">
    <div class="card-body">
        <h4 class="card-title font-weight-bolder mt-3">Crea tu cuenta de empresa</h4>
        <form class="text-left" [ngClass]="!validated? 'was-validated' : '' " #frmSignup="ngForm" (ngSubmit)="signup(frmSignup)">
            <div class="form-group">
                <label class="mb-0" for="empresa">Nombre Empresa</label>
                <input  type="text" 
                        class="form-control form-login" 
                        id="empresa"
                        name="name"
                        #name
                        [(ngModel)]="entity.full_name" 
                        autofocus
                        required >
            </div>
            <div class="form-group">
                <label class="mb-0" for="rubro">Correo electrónico</label>
                <input  type="email" 
                        class="form-control form-login" 
                        id="email"
                        name="email"
                        #email
                        [(ngModel)]="entity.user_name" 
                        required >
            </div>
            <div class="form-group">
                <label class="mb-0" for="correo">RTN</label>
                <input  type="rtn" 
                        class="form-control form-login" 
                        id="correo"
                        name="rtn"
                        #rtn
                        [(ngModel)]="entity.RTN" 
                        required >
            </div>
            <div class="form-group">
                <label class="mb-0" for="sector">Sector empresarial</label>
                <select id="sector"
                        class="form-control form-login"
                        name="sector"
                        #sector
                        [(ngModel)]="entity.business_sector">
                    <option *ngFor="let sector of dataSector" [value]="sector.bsector_id">{{sector.secror_name+" - "+sector.description}}</option>
                </select>
            </div>
            <div class="form-group">
                <label class="mb-0" for="pass">Contraseña</label>
                <input  type="password" 
                        class="form-control form-login" 
                        id="pass"
                        name="pass"
                        #pass
                        [(ngModel)]="entity.password" 
                        required >
            </div>
            <div class="d-flex justify-content-center">
                <button type="submit" class="btn btn-outline-light">
                    <span class="spinner-grow spinner-grow-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
                    {{!loading? "Regístrate" : "Cargando..."}}
                </button>
            </div>
        </form>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApiGlobal } from '../interfaces/globals.interface';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private URI:string = environment.URI_API+"/global";
  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });
  constructor(private http:HttpClient) { }

  public getCountries():Observable<ApiGlobal>{
    return this.http.get<ApiGlobal>(`${this.URI}/countries`,{headers: this.headers});
  }

  public getCategories():Observable<ApiGlobal>{
    return this.http.get<ApiGlobal>(`${this.URI}/categories`,{headers: this.headers});
  }

  public getBusinessSector():Observable<ApiGlobal>{
    return this.http.get<ApiGlobal>(`${this.URI}/bsector`,{headers: this.headers});
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './modules/shared/components/main/main.component';
import { LoginComponent } from './modules/shared/components/login/login.component';
import { SignupComponent } from './modules/shared/components/signup/signup.component';
import { RecoveryComponent } from './modules/shared/components/recovery/recovery.component';

const routes: Routes = [
  { 
    path: 'admin', 
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule) 
  }, 
  { 
    path: 'users', 
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule) 
  }, 
  { 
    path: 'company', 
    loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule) 
  },
  { path: '', component: MainComponent },
  { path: 'login_admin',     component: LoginComponent,    data:{ animation:"login_admin" } },
  { path: 'login_user',     component: LoginComponent,    data:{ animation:"login_user" } },
  { path: 'login_company',  component: LoginComponent,    data:{ animation:"login_company" } },
  { path: 'signup_admin',    component: SignupComponent,   data:{ animation:"signup_admin" } },
  { path: 'signup_user',    component: SignupComponent,   data:{ animation:"signup_user" } },
  { path: 'signup_company', component: SignupComponent,   data:{ animation:"signup_company" } },
  { path: 'recovery',       component: RecoveryComponent, data:{ animation:"recovery" } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

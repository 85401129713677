import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { slideInOutAnimation } from './modules/shared/animations';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  // animations:[
  //   slideInOutAnimation
  // ]
})
export class AppComponent{
  title = 'Findjobs';
  public show:Boolean = false;

  constructor(public router:Router, public _auth:AuthService){
    this.router.events.subscribe((event:Event)=>{
      switch (true) {
        case event instanceof NavigationStart:
          this.show = true;
          break;
        case event instanceof NavigationEnd:
        case event instanceof NavigationError:
        case event instanceof NavigationCancel:
          this.show = false;
          break;
        default:
          break;
      }
    });
  }

  // triggerAnimation(outlet) {
  //   return outlet.activatedRouteData.animation;
  // }

}

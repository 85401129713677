import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { SignUser, ApiSing, SignCompany, User, ApiLogin, LoggedUser, SignAdmin, ApiRecovery } from '../interfaces/User.interface';
import { environment } from 'src/environments/environment';
import { AlertService } from 'ngx-alerts';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  API: string = environment.URI_API;
  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });
  public curUser: LoggedUser;

  constructor(private http: HttpClient, private router: Router,private _alert:AlertService) {

  }

  login(user:User, type:number): Observable<ApiLogin> {
      if (type == 2) {
        return this.http.post<ApiLogin>(`${this.API}/students/singin`, user, { headers: this.headers });
      } else if(type == 1) {
        return this.http.post<ApiLogin>(`${this.API}/company/singin`, user, { headers: this.headers });
      } else if(type == 0){
        return this.http.post<ApiLogin>(`${this.API}/admin/signin`, user, { headers: this.headers });
      }
      
  }

  signUpComany(user:SignCompany):Observable<ApiSing>{
    return this.http.post<ApiSing>(`${this.API}/company/singup`,user,{headers:this.headers});
  }
  signUpUser(user:SignUser):Observable<ApiSing>{
    return this.http.post<ApiSing>(`${this.API}/students/singup`,user,{headers:this.headers});
  }
  signUpAdmin(user:SignAdmin):Observable<ApiSing>{
    return this.http.post<ApiSing>(`${this.API}/admin/signup`,user,{headers:this.headers});
  }

  setUser(user: LoggedUser): void {
    let user_string = JSON.stringify(user);
    localStorage.setItem("currentUser", btoa(user_string));
  }

  setToken(token: string): void {
    localStorage.setItem("accessToken", token);
  }

  setRelogin(pass:string){
    localStorage.setItem("r_login", btoa(pass));
  }

  getRelogin():string{
    return atob(localStorage.getItem("r_login"));
  }

  getToken():String {
    const token = localStorage.getItem("accessToken");
    this.http.post(`${this.API}/global/auth`, {}, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      })
    }).subscribe(
      (data: any) => {
        if (!data.auth) {
          this._alert.warning("La sesión ha expirado");
          this.logout();
          return;
        }
      },
      err => {
        this.logout();
        return;
      }
    )
    return localStorage.getItem("accessToken");
  }

  getCurrentUser(): LoggedUser {
    let user_string = localStorage.getItem("currentUser");
    if (!isNullOrUndefined(user_string)) {
      let user: any = JSON.parse(atob(user_string));
      return user;
    } else {
      return null;
    }
  }

  logout() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("r_login");
    localStorage.removeItem("menuSection");
    this.router.navigate(['/']);
  }

  getHeaders(){
    return new HttpHeaders({
      'Content-Type':'application/json',
      Authorization:`Bearer ${this.getToken()}`
    });
  }

  getFormHeaders(){
    return new HttpHeaders({
      Authorization:`Bearer ${this.getToken()}`
    });
  }
}

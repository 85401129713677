import { Component, OnInit } from '@angular/core';
import { Menu } from 'src/app/interfaces/Menu.interface';
import { DataMenuService } from 'src/app/services/data-menu.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoggedUser } from 'src/app/interfaces/User.interface';
import { AlertService } from 'ngx-alerts';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'afj-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  public menu:Menu[];
  public curUser:LoggedUser;
  public imagep: string;
  url: string = environment.URI_API+"/static/";

  constructor(
    public _menu: DataMenuService,
    private _alert: AlertService,
    private _auth: AuthService
  ) { }

  ngOnInit(): void {    
    this.curUser = this._auth.getCurrentUser();
    this._menu.avatar = this.curUser.avatar; 
    this.getMenu();
  }


  public getMenu(){
    if(this.curUser.role == 1){
      this.menu = this._menu.getCompany();
    }
    else if(this.curUser.role == 2){
      this.menu = this._menu.getMenu();
    } 
    else if(this.curUser.role == 0){
      this.menu = this._menu.getAdmin();
    }
  }

}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'afj-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {
  @Output("onCancel") cancel = new EventEmitter<boolean>();
  @Output("onConfirm") confirm = new EventEmitter<boolean>();
  @Output("onApply") apply = new EventEmitter<boolean>();
  @Input() message:string = "Seguro que desea eliminar el registro?";
  constructor() { }

  ngOnInit(): void {
  }

  onConfirm(){
    this.confirm.emit(true);
  }

  onCancel(){
    this.cancel.emit(true);
  }

  onApply(){
    this.apply.emit(true);
  }

}

import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Country } from 'src/app/interfaces/Globals.interface';
import { SignAdmin, SignUser } from 'src/app/interfaces/User.interface';
import { GlobalService } from 'src/app/services/global.service';
import { AlertService } from 'ngx-alerts';
import { AuthService } from 'src/app/services/auth.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'afj-form-admin',
  templateUrl: './form-admin.component.html',
  styleUrls: ['./form-admin.component.css']
})
export class FormAdminComponent implements OnInit {
  public validated: Boolean = true;
  public question: String = "";
  public answer: String = ``;
  public steps: String[] = [];
  public dataCountry: Country[] = [];
  public gender = [
    {key:0, value:"Masculino"},
    {key:1, value:"Femenino"},
    {key:2, value:"Otro"},
  ]
  public entity: SignAdmin = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    country: "",
    sex: 0,
    n_empleado: 0,
    birth_date: ""
  };
  public signed:Boolean = false;
  public loading:Boolean = false;

  @Output() isSegned = new EventEmitter<{signed:Boolean,email:String}>();

  constructor(
    private _global: GlobalService,
    private _alert: AlertService,
    private _auth: AuthService
  ) { }

  ngOnInit(): void {
    this.setCountry();
  }

  signup(form: NgForm): void {
    if (!form.valid) {
      this.validated = false;
      this._alert.warning("Datos incorrectos");
      return;
    }
    const sign = this._auth.signUpAdmin(this.entity);
    sign.subscribe(
      data=>{
        this.loading = true;
        if (!data.error) {
          // TODO: send email to confirm addess
          this.isSegned.emit({signed:true,email:this.entity.email});
        }
      },
      err=>{ this._alert.danger("Algo salió mal al procesar su registro"); },
      ()=>{this.loading = false;}
    );
  }

  setCountry() {
    const countries = this._global.getCountries();
    countries.subscribe(
      data => {
        this.dataCountry = <Country[]>data.res;
        this.entity.country = this.dataCountry.length>0? this.dataCountry[0].country_name:"";
      },
      err => {
        console.log(err);
        this._alert.danger("Problemas con el servidor");
      }
    );
  }

}

import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { SignCompany } from 'src/app/interfaces/User.interface';
import { GlobalService } from 'src/app/services/global.service';
import { AlertService } from 'ngx-alerts';
import { AuthService } from 'src/app/services/auth.service';
import { NgForm } from '@angular/forms';
import { Sectors } from 'src/app/interfaces/globals.interface';

@Component({
  selector: 'afj-form-company',
  templateUrl: './form-company.component.html',
  styleUrls: ['./form-company.component.css']
})
export class FormCompanyComponent implements OnInit {

  public validated: Boolean = true;
  public dataSector: Sectors[] = [];
  public entity: SignCompany = {
    full_name: "",
    user_name: "",
    password: "",
    business_sector: 1,
    RTN: ""
  };
  public signed: Boolean = false;
  public loading: Boolean = false;

  @Output() isSegned = new EventEmitter<{ signed: Boolean, email: String }>();

  constructor(
    private _global: GlobalService,
    private _alert: AlertService,
    private _auth: AuthService
  ) { }

  ngOnInit(): void {
    this.setSector();
  }

  signup(form: NgForm): void {
    if (!form.valid) {
      this.validated = false;
      this._alert.warning("Datos incorrectos");
      return;
    }
    const sign = this._auth.signUpComany(this.entity);
    sign.subscribe(
      data => {
        this.loading = true;
        if (!data.error) {
          // TODO: send email to confirm addess
          this.isSegned.emit({ signed: true, email: this.entity.user_name });
        }
      },
      err => { this._alert.danger("Algo salió mal al procesar su registro"); },
      () => { this.loading = false; }
    );
  }

  setSector() {
    const countries = this._global.getBusinessSector();
    countries.subscribe(
      data => {
        this.dataSector = <Sectors[]>data.res;
        this.entity.business_sector = this.dataSector.length > 0 ? this.dataSector[0].bsector_id : 1;
      },
      err => {
        console.log(err);
        this._alert.danger("Problemas con el servidor");
      }
    );
  }
}

<nav class="navbar navbar-light sticky-top bg-light flex-md-nowrap p-0 shadow">
    <a class="navbar-brand col-md-4 col-lg-3 col-xl-3 text-center mr-0 px-3" routerLink="">
        <img src="../assets/img/Logo_Header.png" alt="findjobs logo" loading="lazy">
    </a>
    <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse"
        data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <ul class="navbar-nav px-3">
        <li class="nav-item text-nowrap">
            <a class="nav-link" href="/" (click)="logOut()">Sign out</a>
        </li>
    </ul>
</nav>
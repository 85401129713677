import { Injectable } from '@angular/core';
import { Menu } from '../interfaces/Menu.interface';

@Injectable({
  providedIn: 'root'
})
export class DataMenuService {
  public avatar:string = '';
  private userMenu:Menu[] = [
    {
      type:"main",
      data:[
        {
          title: "Perfil", 
          link: "users/profile"
        },
        {
          title: "Postulaciones", 
          link: "users/history"
        },
      ]
    },
    {
      type:"center",
      data:[
        {
          title: "Ver mi CV", 
          link: "users/CV"
        },        
      ]
    },
    {
      type:"menu",
      data:[
        {
          title: "Ofertas Laborales", 
          link: "users/offers"
        },
        {
          title: "Empresas", 
          link: "users/company"
        },
        {
          title: "Experiencia laboral", 
          link: "users/experience"
        },
        {
          title: "Formación académica", 
          link: "users/academic"
        },
        {
          title: "Certificados y/o cursos", 
          link: "users/courses&certifications"
        },
        {
          title: "Otras competencias", 
          link: "users/competence"
        },
        {
          title: "Referencias", 
          link: "users/references"
        },
        {
          title: "Adjuntar documentos", 
          link: "users/attachment"
        },
        {
          title: "Cambiar Password", 
          link: "users/reset"
        },
      ]
    }
  ];
  private companyMenu:Menu[] = [
    {
      type:"main",
      data:[
        {
          title: "Perfil", 
          link: "company/profile"
        },
      ]
    },
    {
      type:"center",
      data:[
        
      ]
    },
    {
      type:"menu",
      data:[           
        {
          title: "Empleos Publicados", 
          link: "company/jobs"
        },
        {
          title: "Candidatos Disponibles", 
          link: "company/aspirant"
        },
        {
          title: "Cambiar Contraseña", 
          link: "company/reset-passw"
        },
      ]
    },
  ];
  private adminMenu:Menu[] = [
    {
      type:"main",
      data:[
        {
          title: "Perfil", 
          link: "admin/profile"
        },
      ]
    },
    {
      type:"center",
      data:[
        
      ]
    },
    {
      type:"menu",
      data:[     
        {
          title: "Sector Empresarial", 
          link: "admin/sectors"
        },      
        {
          title: "Categorías", 
          link: "admin/category"
        },
        {
          title: "Candidatos", 
          link: "admin/users"
        },
        {
          title: "Empresas", 
          link: "admin/company"
        },
        {
          title: "Cambiar Contraseña", 
          link: "admin/reset"
        },
      ]
    },
  ];

  constructor() { }

  public getMenu():Menu[]{
    return this.userMenu;
  }

  public getCompany():Menu[]{
    return this.companyMenu;
  }

  public getAdmin():Menu[]{
    return this.adminMenu;
  }

}

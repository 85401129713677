import { Component, OnInit } from '@angular/core';
import { User, Token } from 'src/app/interfaces/User.interface';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AlertService } from 'ngx-alerts';

@Component({
  selector: 'afj-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  public title: string = 'Inicia sesión de estudiante';
  public image: String = './assets/icon/user.png';
  public signup_url: string = '';
  public user: User = { user_name: '', password: '', remember: false };
  public validated: Boolean = true;
  public type: number = 0;
  public loading: Boolean = false;

  constructor(
    private router: Router,
    private _auth: AuthService,
    private _alert: AlertService
  ) {}

  ngOnInit(): void {
    if (this.router.url == '/login_company') {
      this.title = 'Inicia sesión de empresa';
      this.image = './assets/icon/company.png';
      this.signup_url = '/signup_company';
      this.type = 1;
    }
    if (this.router.url == '/login_user') {
      this.title = 'Inicia sesión de estudiante';
      this.image = './assets/icon/user.png';
      this.signup_url = '/signup_user';
      this.type = 2;
    }
    if (this.router.url == '/login_admin') {
      this.title = 'Inicia sesión de administrador';
      this.image = './assets/icon/admin.png';
      this.signup_url = '/signup_admin';
      this.type = 0;
    }
  }

  login(form: NgForm): void {
    if (!form.valid) {
      this.validated = false;
      this._alert.warning('Datos incorrectos');
      return;
    }
    const process = this._auth.login(this.user, this.type);
    this.loading = true;
    process.subscribe(
      (data) => {
        if (!data.error) {
          this._auth.setToken((<Token>data.res).token);
          // console.log(this.user.user_name);
          (<Token>data.res).user.email = this.user.user_name;
          this._auth.setUser((<Token>data.res).user);
          if (this.user.remember) this._auth.setRelogin(this.user.password);
            if (this.type == 2) {
              return this.router.navigate(['/users']);
            } else if (this.type == 1) {
              return this.router.navigate(['/company']);
            } else if (this.type == 0) {
              return this.router.navigate(['/admin']);
            }
        } else {
          this._alert.warning(<string>data.res);
        }
      },
      (err) => {
        this._alert.danger(
          'Algo salió mal al procesar la solicitud, intenta mas tarde.'
        );
      },
      () => {
        this.loading = false;
      }
    );
  }
}

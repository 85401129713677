import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'avatar'
})
export class AvatarPipe implements PipeTransform {

  transform(value: string): string {
    const pos = value.indexOf(" ")>0 ? value.indexOf(" ")+1 : 0 ;
    return value.substring(0,1).toUpperCase()+value.substring(pos,pos+1).toUpperCase();
  }

}

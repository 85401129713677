<div class="card card-login">
    <div class="card-body">
        <h4 class="card-title font-weight-bolder mt-3">Recupera tu contraseña</h4>
        <form class="text-left" >
            <div class="form-group">
                <div class="form-group">
                    <label class="mb-0" for="correo">Correo electrónico</label>
                    <input  type="email" 
                        class="form-control form-login" 
                        id="correo"
                        name="email"
                        #email
                        required 
                    >
                </div>
            </div>
        </form>
    </div>
</div>
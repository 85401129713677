import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'afj-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css']
})
export class TitleComponent implements OnInit {
  @Input() title:string;
  @Input() refresh:string;
  @Input() add:string;
  @Input() options:string[];
  @Output() onRefresh = new EventEmitter<boolean>();
  @Output() onAdd = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  public onAddListener(){
    this.onAdd.emit(true);
  }

  public onRefreashListener(){
    this.onRefresh.emit(true);
  }

}

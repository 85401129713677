<div class="bg-full login row">
    <div class="col-12 col-sm-12 col-md-6 col-lg-4 offset-lg-4 offset-md-3">
        <div class="col-12 text-center my-4 text-light">
            <div class="card card-login">
                <div class="card-body">
                    <img class="login-image" [src]="image" alt="login empresa">
                    <h4 class="card-title font-weight-bold mt-3">{{title}}</h4>
                    <form class="text-left" #frmLogin="ngForm" [ngClass]="!validated?'was-validated':''"
                        (ngSubmit)="login(frmLogin)">
                        <div class="form-group">
                            <label class="mb-0" for="user">Correo electrónico</label>
                            <input type="email" class="form-control form-login" id="user" name="email" #email="ngModel"
                                [(ngModel)]="user.user_name" aria-describedby="emailHelp" required>
                            <span class="invalid-feedback">Porporcione un correo electrónico válido.</span>
                        </div>
                        <div class="form-group">
                            <label class="mb-0" for="password">Contraseña</label>
                            <input type="password" class="form-control form-login" id="password" name="pass"
                                #pass="ngModel" [(ngModel)]="user.password" required>

                            <div class="d-flex justify-content-around">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="remember" name="remember"
                                    #remember="ngModel" [(ngModel)]="user.remember">
                                    <label class="custom-control-label text-light" for="remember">Recordar</label>
                                </div>
                                <!-- <a href="recovery" class="text-light"><small id="emailHelp" class="form-text">¿Olvidaste tu
                                        contraseña?.</small></a> -->
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button type="submit" class="btn btn-outline-light" [disabled]="loading">
                                <span class="spinner-grow spinner-grow-sm" *ngIf="loading" role="status"
                                    aria-hidden="true"></span>
                                {{!loading? "Iniciar sesión" : "Cargando..."}}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-12 text-center text-light my-4">
            <p class="mb-0">¿No tienes una cuenta?</p>
            <a [routerLink]="signup_url" class="btn btn-outline-light btn-outline-sm">
                Registrate
            </a>
        </div>
    </div>
    <afj-main-footer class="col-12 mt-auto"></afj-main-footer>
</div>
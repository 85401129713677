<div class="bg-full row" [ngClass]="cssClass">
    <div class="container-md">
        <div class="row  text-light">
            <div class="col-12 col-sm-6 d-flex align-items-center">
                <div class="col-12 px-5">
                    <h2 class="font-weight-bold">{{question}}</h2>
                    <p>
                        {{answer}}
                    </p>
                    <div class="row">
                        <div class="col-6 col-sm timestamp" *ngFor="let step of steps; index as i">
                            <span class="d-block outline"></span>
                            <p class="point-state">
                                {{i+1}}
                            </p>
                            <p class="step-detail">{{step}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!isSigned; else elseTemplate">
                <div class="col-12 text-center col-sm-6">
                    <afj-form-user *ngIf="type == 2" (isSegned)="signed($event)"></afj-form-user>
                    <afj-form-company *ngIf="type==1" (isSegned)="signed($event)"></afj-form-company>
                    <afj-form-admin *ngIf="type==0" (isSegned)="signed($event)"></afj-form-admin>
                </div>
            </ng-container>
            <ng-template #elseTemplate>
                <div class="col-12 text-center col-sm-6">
                    <div class="card card-login">
                        <div class="card-body">
                            <h2 class="card-title font-weight-bolder mt-3">
                                ¡Cuenta registrada!
                            </h2>
                            <p>Hemos enviado un correo de confirmación a {{signedMail}}</p>
                            <a [routerLink]="type==2? '/login_user':'/login_company'" class="btn btn-outline-light">Iniciar sesión</a>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>

    <afj-main-footer class="col-12 mt-auto"></afj-main-footer>
</div>
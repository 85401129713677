import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'afj-loader-bar',
  templateUrl: './loader-bar.component.html',
  styleUrls: ['./loader-bar.component.css']
})
export class LoaderBarComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}

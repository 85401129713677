<nav id="sidebarMenu" class="col-md-4 col-lg-3 col-xl-3 d-md-block bg-primary sidebar collapse">
    <div class="card-body card-avatar">    
        <!-- <img src="{{url}}{{curUser.avatar}}" class="float-left rounded-circle" width="25%"> -->
        <img src="{{url}}{{this._menu.avatar}}" class="float-left rounded-circle" width="25%">
        <div class="message">
            <h5 class="card-title mb-0">{{ curUser.first_name}}</h5>
            <h5 class="card-title">{{ curUser.last_name }}</h5>
            <h6 class="card-subtitle font-weight-light mb-2">{{curUser.email}}</h6>
        </div>
    </div>
    
    <div class="sidebar-sticky pt-3 text-center">
        <ul class="nav flex-column mb-4" *ngIf="menu[0] && menu[0].type=='main'">
            <li class="nav-item" *ngFor="let link of menu[0].data">
                <a class="nav-link" [routerLink]="link.link" routerLinkActive="active">
                    {{link.title}}
                </a>
            </li>
        </ul>

        <ul class="nav flex-column mb-2" *ngIf="menu[1] && menu[1].type=='center'">
            <li class="nav-item" *ngFor="let link of menu[1].data">
                <a class="nav-link" [routerLink]="link.link" routerLinkActive="active">
                    <button class="btn btn-outline-light btn-rounded">{{link.title}}</button>
                    
                </a>
            </li>
        </ul>
       
        <ul class="nav nav-secondary flex-column mb-4" *ngIf="menu[2] && menu[2].type=='menu'">
            <li class="nav-item" *ngFor="let link of menu[2].data">
                <a class="nav-link" [routerLink]="link.link" routerLinkActive="active">
                    {{link.title}}
                    <span class="outline"></span>
                </a>
            </li>
        </ul>
    </div>
</nav>
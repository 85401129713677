<div class="card card-login">
    <div class="card-body">
        <h4 class="card-title font-weight-bolder mt-3">Crea tu cuenta de administrador</h4>
        <form class="text-left" [ngClass]="!validated? 'was-validated' : '' " #frmSignup="ngForm"
            (ngSubmit)="signup(frmSignup)">
            <div class="form-group">
                <label class="mb-0" for="name">Nombre</label>
                <input type="text" class="form-control form-login" id="name" name="name" #name
                    [(ngModel)]="entity.first_name" autofocus required>
            </div>
            <div class="form-group">
                <label class="mb-0" for="last">Apellido</label>
                <input type="text" class="form-control form-login" id="last" name="last" #last
                    [(ngModel)]="entity.last_name" autofocus required>
            </div>
            <div class="form-group">
                <label class="mb-0" for="correo">Correo electrónico</label>
                <input type="email" class="form-control form-login" id="correo" name="email" #email
                    [(ngModel)]="entity.email" required>
            </div>
            <div class="form-group">
                <label class="mb-0" for="depart">Departamento</label>
                <select id="depart" class="form-control form-login" name="country" #country
                    [(ngModel)]="entity.country">
                    <option *ngFor="let country of dataCountry" [value]="country.country_name">{{country.country_name |
                        lowercase}}</option>
                </select>
            </div>
            <div class="form-group">
                <label class="mb-0" for="sex">Genero</label>
                <Select class="form-control form-login" id="sex" name="sex" #sex [(ngModel)]="entity.sex"
                    ngDefaultControl required>
                    <option *ngFor="let item of gender" [value]="item.key">{{item.value}}</option>
                </Select>
            </div>
            <div class="form-group">
                <label class="mb-0" for="bday">Fecha de nacimiento</label>
                <input type="date" class="form-control form-login" id="bday" name="bday" #bday
                    [(ngModel)]="entity.birth_date" required>
            </div>
            <div class="form-group">
                <label class="mb-0" for="no">Código de empleado</label>
                <input type="text" class="form-control form-login" id="no" name="no" #no
                    [(ngModel)]="entity.n_empleado" autofocus required>
            </div>
            <div class="form-group">
                <label class="mb-0" for="pass">Contraseña</label>
                <input type="password" class="form-control form-login" id="pass" name="pass" #pass
                    [(ngModel)]="entity.password" required>
            </div>
            <div class="d-flex justify-content-center">
                <button type="submit" class="btn btn-outline-light">
                    <span class="spinner-grow spinner-grow-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
                    {{!loading? "Regístrate" : "Cargando..."}}
                </button>
            </div>
        </form>
    </div>
</div>
<div class="main row">
    <div class="col-12 col-sm-6 offset-sm-3 mt-5">
        <div class="col-9 mx-auto mt-5">
            <img src="./assets/img/Logo_Inicio.png" class="img-fluid" alt="FindJobs logo">
        </div>
        <div class="col-12 text-center mt-5 text-light">
            <h4 class="font-weight-bold">Inicia sesión</h4>
            <div class="d-block d-sm-flex justify-content-center mt-4">
                <a routerLink="login_company" class="btn btn-outline-light btn-img mr-sm-3 mr-xs-0">
                    <img class="image-btn" src="./assets/icon/company.png" alt="login empresa"> Empresa</a>
                <a routerLink="login_user" class="btn btn-outline-light btn-img ml-sm-3 ml-xs-0">
                    <img class="image-btn" src="./assets/icon/user.png" alt="login estudiantes">Estudiante</a>
            </div>
            <div class="d-block d-sm-flex justify-content-center mt-4">               
                <a routerLink="login_admin" class="btn btn-outline-light btn-img ml-sm-3 ml-xs-0">
                    <img class="image-btn" src="./assets/icon/admin.png" alt="login administrador">Administrador</a>
            </div>

        </div>
        <div class="col-12 text-center text-light mt-3">
            <!-- <p class="mb-0">¿No tienes una cuenta?</p>
            <a routerLink="signup" class="btn btn-outline-light btn-outline-sm">
                Regístrate
            </a> -->
        </div>
    </div>
    <afj-main-footer class="col-12 mt-auto"></afj-main-footer>
</div>
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 title-header">
    <h1 class="h2 text-title"> {{title}} </h1>
    <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group mr-2">
            <button type="button" 
                class="btn btn-sm btn-outline-secondary" 
                *ngIf="refresh"
                (click)="onRefreashListener()"
            >{{refresh}}</button>
            <button type="button" 
                class="btn btn-sm btn-outline-secondary" 
                *ngIf="add"
                (click)="onAddListener()"
            >{{add}}</button>
        </div>
        <button type="button" class="btn btn-sm btn-outline-secondary dropdown-toggle" *ngIf="options">
            <span data-feather="calendar"></span>
            This week
        </button>
    </div>
</div>
<div class="modal-header">
    <span class="spacer"></span>
    <p class="modal-title" id="modal-basic-title">Confirmación</p>
    <span class="spacer"></span>
    <hr>
</div>
<div class="modal-body">
    <p class="text-center">{{message}}</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-light" (click)="onCancel()">Cancelar</button>
    <button type="button" class="btn btn-outline-light" (click)="onConfirm()">Eliminar</button>
</div>
